.titles p {
    color: rgb(221, 237, 241);
    margin: 10px;
    font-size: 25px;
}
.title-box {
    display: flex;
    align-items: center; /* Vertically center elements */
    justify-content: flex-start; /* Horizontally left-align elements */
  }

.titles img {
    height: 35px;
}