.language-selector select {
    appearance: none; /* Hide the default arrow */
    background: transparent; /* Transparent background */
    border: 1px solid #ccc; /* Gray border */
    border-radius: 5px; /* Rounded corners */
    padding: 5px 10px; /* Padding around the select */
    font-size: 20px; /* Font size */
    color: #fff;
  }
  
  /* Style the option elements (optional) */
  .language-selector select option {
    background-color: #000000; /* White background for options */
    padding: 10px; /* Padding for options */
    font-size: 16px; /* Font size for options */
  }

  .language-selector {
    position: fixed; /* Fixed positioning */
    top: 0; /* Position it at the top */
    right: 0; /* Position it at the right */
    padding: 10px; /* Adjust the padding as needed */
    z-index: 999; /* Set an appropriate z-index value to control stacking order */
  }