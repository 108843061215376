.SaveContactButton {
    background-color: rgb(43, 54, 204); /* Blue background color */
    color: #fff; /* White text color */
    border: none; /* Remove border */
    margin:15px;
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding around the text */
    font-size: 20px; /* Font size */
    cursor: pointer; /* Cursor style on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}
  
.SaveContactButton:hover {
    background-color: rgb(37, 40, 133); /* Darker blue on hover */
}

.SaveContact p {
    color:rgb(2, 174, 10);
    font-size: 14px;
}